import { Injectable } from '@angular/core';
import { BookService } from '../data/book.service';

@Injectable()
export class AppService {

  public readonly fullscreenEnabled = document.fullscreenEnabled;
  public viewMode: 'doublepageBook' | 'singlepageBook' = 'singlepageBook';
  public fullspaceIsOpen = false;

  constructor(
    private bookService: BookService,
  ) {
  }

  public closeFullspace(): void {
    this.fullspaceIsOpen = false;
  }

  public openFullspaceViewAt(index: number): void {
    this.bookService.showPage(index);
    this.fullspaceIsOpen = true;
  }
}
