import { Injectable } from '@angular/core';
import { DataService } from './data/data.service';

@Injectable()
export class MetricsService {

  public readonly headerAndFooterHeight: number = 100;
  public readonly bookHorizontalMargin: number = 10;
  private mainContainer: HTMLElement;

  public doubleModePageWidth = 0;
  public doubleModeBookWidth = 0;
  public doubleModePageHeight = 0;
  public singleModePageWdith = 0;
  public singleModePageHeight = 0;

  constructor(
    private dataService: DataService,
  ) {
  }

  public setMainContainer(element: HTMLElement): void {
    this.mainContainer = element;
    this.update();
  }

  public update() {
    this.doubleModePageWidth = this.calcPageWidthForDoublePage();
    this.doubleModePageHeight = this.dataService.getUnscaledPageHeight() * (this.doubleModePageWidth / this.dataService.getUnscaledPageWidth());
    this.doubleModeBookWidth = (this.doubleModePageWidth + this.bookHorizontalMargin) * 2;

    this.singleModePageWdith = this.calcPageWidthForSingePage();
    this.singleModePageHeight = this.dataService.getUnscaledPageHeight() * (this.singleModePageWdith / this.dataService.getUnscaledPageWidth());
  }

  private calcPageWidthForSingePage(): number {
    const avaiableWidth = this.mainContainer.clientWidth - 2 * this.bookHorizontalMargin;
    const avaiableHeight = this.mainContainer.clientHeight - this.headerAndFooterHeight;

    const unscaledBookWidth = this.dataService.getUnscaledPageWidth();
    const unscaledBookHeight = this.dataService.getUnscaledPageHeight();

    const fw = avaiableWidth / unscaledBookWidth;
    const fh = avaiableHeight / unscaledBookHeight;

    const f = Math.min(fw, fh);

    const pageWidth = unscaledBookWidth * f;

    return pageWidth;
  }

  private calcPageWidthForDoublePage(): number {
    const avaiableWidth = this.mainContainer.clientWidth - 2 * this.bookHorizontalMargin;
    const avaiableHeight = this.mainContainer.clientHeight - this.headerAndFooterHeight;

    const unscaledBookWidth = this.dataService.getUnscaledPageWidth() * 2;
    const unscaledBookHeight = this.dataService.getUnscaledPageHeight();

    const fw = avaiableWidth / unscaledBookWidth;
    const fh = avaiableHeight / unscaledBookHeight;

    const f = Math.min(fw, fh);

    const pageWidth = (unscaledBookWidth * f) / 2;

    return pageWidth;
  }
}
