import { Component, OnInit, Input } from '@angular/core';

@Component({

    selector: 'fc-page',
    template: `<div class="page-image-container" *ngIf="src!=''"><img src={{src}} style='width:100%;height:auto' (load)="loaded=true"></div>
               <div class="page-loader" *ngIf="!loaded&&src!=''" [ngStyle]="{'background-color':'rgba(255,255,255,1)'}" > </div>
               <div class="page-image-shadow" *ngIf="src!=''" [ngStyle]="{'background-image':'url('+shadowSrc+')'}" > </div>
              `,
    styleUrls: ['./page.component.less'],
})


export class PageComponent implements OnInit {
    @Input() loaded: boolean;
    private _src: string;
    @Input() shadowSrc: string;


    constructor() {
    }

    public ngOnInit(): void {

    }

    @Input()
    public set src(value: string) {
        this.loaded = false;
        this._src = value;
    }

    public get src(): string {
        return this._src;
    }

}
