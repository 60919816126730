<div class="error-main modal-dialog">
  <div class="header">
    <div class="header-title">Error</div>
  </div>
  <div class="content">
    {{globalErrorService.lastError}}
  </div>
  <div class="modal-button-container">
      <div class="modal-button" (click)="dialogsService.closeError()">OK</div>
  </div>
</div>
