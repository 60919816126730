
<div #scrollContainer class="scroll-container" (mousemove)="handleMouseMove()" (swipe)="handleSwipe($event)">
  <div (panStart)="handleImagePanStart($event)" (pan)="handleImagePan($event)">
    <div class="page-image-container" 
      [class.isZoomedIn]="isZoomedIn" 
      [class.allowTransition]="allowTransition" 
      [style]="getImageContainerStyle()">
        <img [src]="bookService.getLargePageAtOffset(1)" class="page-image" (tap)="toggleZoom($event)">
    </div>
  </div>
</div>
<div class="header" [class.hidden]="!showButtons">
  <div class="zoom button" [class.zoom-out]="isZoomedIn" title="Zoom in/out" (click)="toggleZoom($event)"></div>
  <div class="fs button" *ngIf="appService.fullscreenEnabled" title="Toggle fullscreen" (click)="toggleFullscreen()"></div>
  <div class="close button" title="Close (Esc)" (click)="close()"></div>
</div>
<div class="prev button mid" [class.hidden]="!showButtons" title="Previous (arrow left)" (click)="bookService.prevPage()"></div> 
<div class="next button mid" [class.hidden]="!showButtons" title="Next (arrow right)"(click)="bookService.nextPage()"></div>
<div class="footer" [class.hidden]="!showButtons">
  {{ dataService.getCatalogName() }}, S. {{bookService.singlePageNumberDisplayText}}
</div>

