import { Component } from '@angular/core';
import { BookService } from 'src/app/data/book.service';
import { MetricsService } from 'src/app/metrics.service';
import { AppService } from '../../app.service';
import { DialogsService } from '../../dialogs/dialogs.service';

@Component({
  selector: 'fc-book-single-page',
  templateUrl: './book-single-page.component.html',
  styleUrls: ['./book-single-page.component.less']
})
export class BookSinglePageComponent {

  constructor(
    public bookService: BookService,
    private dialogsService: DialogsService,
    public appService: AppService,
    public metricsService: MetricsService,

  ) { }

  public handleRightSideGesture() {
    if (!this.dialogsService.isDialogOpen()) {
      this.bookService.nextPage();
    }
  }

  public handleLeftSideGesture() {
    if (!this.dialogsService.isDialogOpen()) {
      this.bookService.prevPage();
    }
  }

  public openFullspaceView(): void {
    const desiredIndex = this.bookService.pageIndex;
    if (desiredIndex >= this.bookService.firstPageIdx && desiredIndex <= this.bookService.lastPageIdx) {
      this.appService.openFullspaceViewAt(desiredIndex);
    }
  }
}
