import { Component, Input, Output, AfterViewInit, EventEmitter } from '@angular/core';

@Component({

    selector: 'fc-index-item',
    template: `<div class="index-item">
                <div class="row" (click)="handleClick()">
                    <div class="title">{{itemObject['@attributes'].name}}</div>
                    <div *ngIf="!hasItems()">{{itemObject['@attributes'].page}}</div>
                </div>
                <div *ngFor="let item of getItems()" class="item">
                            <fc-index-item [itemObject]=item (rowClick)="handleItemRowClick($event)"></fc-index-item>
                        </div>

                </div>`
})


export class IndexItemComponent implements AfterViewInit {
    @Input() public itemObject: any;
    @Output() rowClick: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
    }

    public ngAfterViewInit(): void {
    }

    public handleItemRowClick(event): void {
        this.rowClick.emit(event);
    }

    public handleClick(): void {
        if (!this.hasItems()) {
            this.rowClick.emit({ target: this });
        }
    }

    public hasItems(): boolean {
        return this.getItems().length > 0;
    }

    public getItems(): any[] {
        const item = this.itemObject.item;
        if (item == null) {
            return [];
        }

        if (item[0] === undefined) {
            return [item];
        }

        return item;
    }

}
