import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DataService } from './data.service';

export interface PageChange {
  oldPageIndex: number | undefined;
  pageIndex: number;
}  

@Injectable()
export class BookService {
  private lastPageUpdateTime = 0;
  private _pageIndex = 0;
  public readonly pageChange$: Subject<PageChange> = new Subject();
  
  constructor(
    private dataService: DataService,
  ) {
  }

  public getDoublePageBasedIndex(pageIndex: number): number {
    if (pageIndex % 2 === 1) {
      return pageIndex + 1;
    } else {
      return pageIndex;
    }
  }

  public get doublePageBasedIndex(): number {
    return this.getDoublePageBasedIndex(this._pageIndex);
  }

  public get pageIndex(): number {
    return this._pageIndex;
  }

  public get doublePageNumberDisplayText(): string {
    const index = this.doublePageBasedIndex + this.dataService.getFirstPageOffset();
    if (this.doublePageBasedIndex === 0) {
      return String(index + 1);
    }

    if (this.doublePageBasedIndex >= this.lastPageIdx) {
      return String(index);
    }
    return index  + ' | ' + (index  + 1);
  }

  public get singlePageNumberDisplayText(): string {
    const index = this._pageIndex + this.dataService.getFirstPageOffset();
    return String(index + 1);
  }

  public get firstPageIdx(): number {
    return  0;
  }

  public get lastPageIdx(): number {
    return this.dataService.getPageCount() - 1;
  }

  public isFirstPage(): boolean {
    return this.firstPageIdx === this.pageIndex;
  }

  public isLastPage(): boolean {
    return this.lastPageIdx === this.pageIndex;
  }

  public prevPage(): void {
    this.showPage(this.pageIndex - 1);
  }

  public prevDoublePage(): void {
    this.showPage(this.getDoublePageBasedIndex(this._pageIndex)-2);
  }

  public prevPage10(): void {
    this.showPage(this.pageIndex - 10);
  }

  public firstPage(): void {
    this.showPage(this.firstPageIdx);
  }

  public nextPage(): void {
    this.showPage(this.pageIndex + 1);
  }

  public nextDoublePage(): void {
    this.showPage(this.getDoublePageBasedIndex(this._pageIndex)+2);
  }

  public nextPage10(): void {
    this.showPage(this.pageIndex + 10);
  }

  public lastPage(): void {
    this.showPage (this.lastPageIdx);
  }

  public cleanIndex(): void {
    this._pageIndex = this.doublePageBasedIndex;
  }

  public showPage(value: number): void {
    const now = new Date().getTime();
    if(isNaN(value) || (now - this.lastPageUpdateTime < 500)){
      return;
    }
    this.lastPageUpdateTime = now;
    const lastPageIndex = this._pageIndex;
    this._pageIndex = this.calcAllowedPageIdx(value);
    this.pageChange$.next({
      oldPageIndex: lastPageIndex,
      pageIndex: this._pageIndex
    })
  }

  private calcAllowedPageIdx(value: number): number {
    value = value | 0; // force integer
    if (value < this.firstPageIdx) {
        value = this.firstPageIdx;
    }

    if (value > this.lastPageIdx) {
        value = this.lastPageIdx;
    }

    return value;
  }

  public calcAllowedOffset(offset: number): number {
      if (this._pageIndex + offset < this.firstPageIdx) {
          return this.firstPageIdx - this._pageIndex;
      }

      if (this._pageIndex + offset > this.lastPageIdx + 1) {
          return this.lastPageIdx - this._pageIndex + 1;
      }

      return offset;
  }

  public getLargePageAtOffset(offset: number): string {
    return this.dataService.getLargePageSrcAt(this.pageIndex + offset - 1);
  }

  public getPageAtOffset(offset: number): string {
      return this.getPageAt(this.pageIndex + offset);
  }

  public getPageAt(idx: number): string {
      return this.dataService.getPageSrcAt(idx - 1);
  }

  public getFirstPage(): string {
      return this.getPageAt(this.firstPageIdx + 1);
  }

  public getLastPage(): string {
      return this.getPageAt(this.lastPageIdx);
  }

}
