import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { DataService } from "src/app/data/data.service";
import { RequestLoader } from "src/app/data/RequestLoader";

declare const FcSettings;

export interface SearchResult {
  status: 'idle' | 'ok' | 'error';
  items: [];
  query: string;
  count: number;
}

@Injectable()
export class SearchService {

  private loader: RequestLoader;
  private data: any;
  public items: any[];
  public searchResult$ = new ReplaySubject<SearchResult>(1);
  public inputValue = '';

  constructor(
    private dataService: DataService,
  ){
    this.searchResult$.next({
      status: 'idle', 
      items: [],
      query: '',
      count: 0,
    });
  }

  public searchFor(word: string): void {
    if (word.length > 1) {
        this.items = [];
        const url: string = FcSettings.pathPrefix
            + FcSettings.ftsPath
            + '?c=' + this.dataService.getCid()
            + '&q=' + word
            + '&lang=' + FcSettings.lang;

        this.loader = new RequestLoader();
        this.loader.get(url).then(
            (value) => { this.onLoadSuccess(value); },
            (value) => { this.onLoadFailed(value); }
        );
    }
  }

  
  private onLoadSuccess(value): void {
    this.data = JSON.parse(value);
    this.searchResult$.next({
      status: 'ok',
      items:  this.data.hits.hit || [],
      query: this.data.query,
      count: this.data.hits['@attributes'].count,
    });
  }

  private onLoadFailed(value): void {
      console.log('search.onLoadFailed');
      this.searchResult$.next({
        status: 'error',
        items:  [],
        query: '',
        count: 0,
      });  
  }
}