
<div class="opener-text"
      [innerHTML]=""
      ></div>

<div id="shadowLeft"
      class="book-shadow left"
      [ngStyle]="bookFlipService.getBookShadowLeftStyle()"></div>
<div id="shadowRight"
      class="book-shadow right"
      [ngStyle]="bookFlipService.getBookShadowRightStyle()"></div>

<div class="doublepage" id="actual">
    <fc-page [src]="bookFlipService.getActPageAtOffset(0)"
              [shadowSrc]="'./assets/book/pageInnerShadowL.png'"
              class="page-left">
    </fc-page>
    <fc-page [src]="bookFlipService.getActPageAtOffset(1)"
              [shadowSrc]="'./assets/book/pageInnerShadowR.png'"
              class="page-right">
    </fc-page>
</div>

<div class="doublepage click-container" id="clickIt">
  <div [class.disabled-button]="bookService.isFirstPage()" class="page-flip-button left" (click)="handleLeftSideGesture()" (swiperight)="handleLeftSideGesture()"></div>
  <div [class.disabled-button]="bookService.isFirstPage()" class="page-fullspace-button left" (click)="openFullspaceView(-1)"></div>
  <div [class.disabled-button]="bookService.isLastPage()" class="page-fullspace-button right" (click)="openFullspaceView(0)"></div>
  <div [class.disabled-button]="bookService.isLastPage()" class="page-flip-button right" (click)="handleRightSideGesture()" (swipeleft)="handleRightSideGesture()"></div>
</div>

<div id="tweenDiv" [ngStyle]="{'height':'100%','visibility':bookFlipService.showTweenDiv?'visible':'hidden','opacity':'1'}">

    <div class="doublepage" id="front" [ngStyle]="{'clip':bookFlipService.getClipRect()}">
        <fc-page [src]="bookFlipService.getFrontPageAtOffset(0)"
                  [shadowSrc]="'./assets/book/pageInnerShadowL.png'"
                  class="page-left">
        </fc-page>
        <fc-page [src]="bookFlipService.getFrontPageAtOffset(1)"
                  [shadowSrc]="'./assets/book/pageInnerShadowR.png'"
                  class="page-right">
        </fc-page>
    </div>
    <div class="doublepage" id="back" [ngStyle]="{'clip':bookFlipService.getClipRect()}">
        <fc-page [src]="bookFlipService.getBackPageAtOffset(0)"
                  [shadowSrc]="'./assets/book/pageInnerShadowL.png'"
                  [ngClass]="bookFlipService.getClassLeft()"
                  [ngStyle]="bookFlipService.getStyleLeft()">
        </fc-page>
        <fc-page [src]="bookFlipService.getBackPageAtOffset(1)"
                  [shadowSrc]="'./assets/book/pageInnerShadowR.png'"
                  [ngClass]="bookFlipService.getClassRight()"
                  [ngStyle]="bookFlipService.getStyleRight()">
        </fc-page>
    </div>
</div>
    <div id="preload" style="display:none">
    <img [src]="bookFlipService.getPageAtOffset(2)" />
    <img [src]="bookFlipService.getPageAtOffset(3)" />
    <img [src]="bookFlipService.getPageAtOffset(-1)" />
    <img [src]="bookFlipService.getPageAtOffset(-2)" />
    <img [src]="bookFlipService.getPageAtOffset(10)" />
    <img [src]="bookFlipService.getPageAtOffset(11)" />
    <img [src]="bookFlipService.getPageAtOffset(-9)" />
    <img [src]="bookFlipService.getPageAtOffset(-10)" />

    <img src="{{bookService.getFirstPage()}}" />
    <img src="{{bookService.getLastPage()}}" />

</div>

