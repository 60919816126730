import { Component, ErrorHandler, OnInit } from '@angular/core';
import { DialogsService } from '../dialogs.service';
import { LangService } from '../../../data/lang.service';
import { GlobalErrorService } from '../../../utils/global-error.service';

@Component({
  selector: 'fc-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.less']
})
export class ErrorComponent implements OnInit {

  public globalErrorService: GlobalErrorService;

  constructor(
    public dialogsService: DialogsService,
    public langService: LangService,
    private errorHandler: ErrorHandler,
  ) {
    // cast to GlobalErrorService
    this.globalErrorService = errorHandler as GlobalErrorService;
   }

  ngOnInit(): void {
  }

}
