import { animate, state, style, transition, trigger } from '@angular/animations';

export const showHideFullspace = trigger('show-hide-fullspace', [
  transition(':enter', [
    style({opacity: 0}),
    animate('250ms ease-in', style({opacity: 1}))
  ]),
  transition(':leave', [
    style({opacity: 1}),
    animate('250ms ease-out', style({opacity: 0}))
  ])
]);
