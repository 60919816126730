import { Component, OnInit } from '@angular/core';
import { LangService } from '../data/lang.service';
import { DataService } from '../data/data.service';
import { DialogsService } from './dialogs/dialogs.service';

@Component({
    selector: 'fc-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.less'],
})


export class HeaderComponent implements OnInit {
    constructor(
        public dialogsService: DialogsService,
        public langService: LangService,
        public dataService: DataService,
    ) {
    }

    public ngOnInit(): void {
    }

}
