import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'fc-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.less']
})
export class LoadingIndicatorComponent implements OnInit, OnDestroy {

  private intervalId: any;
  private count: number;
  public loadingText: string;

  constructor() { }

  ngOnInit(): void {
    this.count = 0;
    this.intervalId = setInterval(() => {
      ++this.count;

      this.loadingText = 'Loading';
      let dots = this.count % 4;
      while(dots-- > 0){
        this.loadingText += '.';
      }
    }, 200);
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

}
