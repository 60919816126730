import { Component } from '@angular/core';
import { LangService } from '../data/lang.service';
import { DialogsService } from './dialogs/dialogs.service';
import { BookService } from '../data/book.service';
import { DataService } from '../data/data.service';
import { AppService } from './app.service';

declare var FcSettings;

@Component({

    selector: 'fc-footer',
    templateUrl: './footer.component.html'
})

export class FooterComponent {
    constructor(
                public bookService: BookService,
                public langService: LangService,
                public dialogsService: DialogsService,
                private dataService: DataService,
                public appService: AppService,
    ) {
    }

    public getDoublePageNumberDisplayText(): string {
      if (this.appService.viewMode === 'doublepageBook') {
        return this.bookService.doublePageNumberDisplayText;
      } else {
        return this.bookService.singlePageNumberDisplayText;
      }
    }

    public handleInputKeyUp(keyEvent:KeyboardEvent): void {
      // don't allow pagecontrol by e.g. arrow keys.
      keyEvent.stopPropagation();
    }

    public gotoPageByInput(pageNum): void {
      this.bookService.showPage(Number(pageNum) - this.dataService.getFirstPageOffset() - 1);
    }

    public prevPage(): void {
      if (this.appService.viewMode === 'doublepageBook') {
        this.bookService.prevDoublePage();
      } else {
        this.bookService.prevPage();
      }
    }

    public nextPage(): void {
      if (this.appService.viewMode === 'doublepageBook') {
        this.bookService.nextDoublePage();
      } else {
        this.bookService.nextPage();
      }
    }

    public openPdf(offset: number): void {
      let page; 
      if(this.appService.viewMode === 'doublepageBook'){
        page = this.bookService.doublePageBasedIndex + offset + this.dataService.getFirstPageOffset() + 1;
      }else{
        page = this.bookService.pageIndex + this.dataService.getFirstPageOffset() + 1;
      }

      window.open(FcSettings.pdfDomain
                + FcSettings.pdfPath
                + '?cid=' + this.dataService.getCid()
                + '&page=' + page);
    }

    public print(offset: number): void {
      let page; 
      if(this.appService.viewMode === 'doublepageBook'){
        page = this.bookService.doublePageBasedIndex + offset;
      }else{
        page = this.bookService.pageIndex;
      }

      window.open('./assets/print.html?src=' + this.dataService.getLargePageSrcAt(page));
    }
}
