import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { TweenLite } from 'gsap';
import { BookService } from 'src/app/data/book.service';
import { MetricsService } from 'src/app/metrics.service';

@Injectable()
export class BookFlipService {

  public readonly shadowWidth: number = 10;
  private readonly tweenDurationMs: number = 400;

  private tweenDir = 0;
  private jumpOffset = 0;
  private clipBorderLeft = 0;
  private clipBorderRight = 0;
  public showTweenDiv: boolean;

  constructor(
    private bookService: BookService,
    private metricsService: MetricsService,
  ) {
    this.bookService.pageChange$.subscribe(pageChange => {
     
      const oldPage = this.bookService.getDoublePageBasedIndex(pageChange.oldPageIndex || 0);
      const newPage = this.bookService.getDoublePageBasedIndex(pageChange.pageIndex);
      const offset = newPage - oldPage;

      if(offset !== 0 && !this.showTweenDiv) {
        if (offset > 0) {
          this.tweenRight(offset);
        } else if (offset < 0) {
          this.tweenLeft(offset);
        }
      }
    })
  }

  public getClassLeft(): any {
    return {
      'page-left': true,
      back: true,
      tween: this.tweenDir === 1
    };
  }

  public getClassRight(): any {
    return {
      'page-right': true,
      back: true,
      tween: this.tweenDir === -1
    };
  }

  public getStyleLeft(): any {
    return {
      transform: 'translateX(' + (this.metricsService.doubleModePageWidth * 2) + 'px)'
    };
  }

  public getStyleRight(): any {
    return {
      transform: 'translateX(' + (-this.metricsService.doubleModePageWidth * 2) + 'px)'
    };
  }

  public getBookShadowLeftStyle(): any {
    const style: any = {};

    if (this.bookService.pageIndex <= 2) {
      style.width = '0px';
      style.opacity = '0';
      style.left = '0';
    } else if (this.bookService.pageIndex === 4) {
      style.width = '2px';
      style.opacity = '0.5';
      style.left = '-2px';
    } else {
      style.width = '4px';
      style.opacity = '1';
      style.left = '-4px';

    }
    return style;
  }

  public getBookShadowRightStyle(): any {
    const style: any = {};

    if (this.bookService.pageIndex >= this.bookService.lastPageIdx - 2) {
      style.width = '0px';
      style.opacity = '0';
      style.right = '0';
    } else if (this.bookService.pageIndex >= this.bookService.lastPageIdx - 4) {
      style.width = '2px';
      style.opacity = '0.5';
      style.right = '-2px';
    } else {
      style.width = '4px';
      style.opacity = '1';
      style.right = '-4px';
    }
    return style;
  }

  public resetClipBorders(): void {
    this.clipBorderLeft = 0;
    this.clipBorderRight = this.metricsService.doubleModePageWidth * 2;
  }

  public getClipRect(): string {
    // top right bottom left
    return 'rect(0px, ' + this.clipBorderRight + 'px, 2000px, ' + this.clipBorderLeft + 'px)';
  }

  public getPageAtOffset(offset: number): string {
    return this.bookService.getPageAt(this.bookService.doublePageBasedIndex + offset);
  }

  public getActPageAtOffset(offset: number): string {
    const actPageIdx = this.bookService.doublePageBasedIndex - this.jumpOffset + offset;
    if((offset === 0 && actPageIdx <= this.bookService.firstPageIdx) ||
      (offset === 1 && actPageIdx > this.bookService.lastPageIdx)){
      return '';
    }

    return this.bookService.getPageAt(this.bookService.doublePageBasedIndex + offset);
  }

  public getFrontPageAtOffset(offset: number): string {
    return this.bookService.getPageAt(this.bookService.doublePageBasedIndex - this.jumpOffset + offset);
  }

  public getBackPageAtOffset(offset: number): string {
    return this.bookService.getPageAt(this.bookService.doublePageBasedIndex + offset);
  }

  private tweenLeft(offset: number): void {
    this.tweenDir = -1;
    this.jumpOffset = offset;
    this.resetClipBorders();
    this.showTweenDiv = true;
    
    TweenLite.to(this,
      this.tweenDurationMs / 1000, {
      clipBorderLeft: this.metricsService.doubleModePageWidth,
      onComplete: () => this.updateOnTweenFinished(),
    });
  }

  private tweenRight(offset: number): void {
    this.tweenDir = 1;
    this.jumpOffset = offset;
    this.resetClipBorders();
    this.showTweenDiv = true;

    TweenLite.to(this,
      this.tweenDurationMs / 1000, {
      clipBorderRight: this.metricsService.doubleModePageWidth,
      onComplete: () => this.updateOnTweenFinished(),
    });
  }

  private updateOnTweenFinished(): void {
    this.tweenDir = 0;
    this.jumpOffset = 0;
    this.showTweenDiv = false;
  }

}

