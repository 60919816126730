import { Injectable } from '@angular/core';
import { RequestLoader } from './RequestLoader';
declare var FcSettings;

@Injectable()
export class DataService {
    private data: any;
    private pages: any;
    private loader: RequestLoader;

    constructor() {
        this.loader = new RequestLoader();
    }


    public loadFinished(): boolean {
        return this.loader.status === RequestLoader.STATUS_OK;
    }

    public loadContentData(url): Promise<any> {
      return new Promise <any> ((resolve, reject) => {
            this.loader.get(url).then(
                (value) => {
                    this.handleDataLoadFinished(value);
                    resolve(true);
                },
                (value) => {
                    this.handleDataLoadedReject(value);
                    reject(false);
                }
            );

        });
    }

    private handleDataLoadFinished(value): void {
        this.data = JSON.parse(value);
        this.pages = this.data.content.pages;
    }

    private handleDataLoadedReject(value): void {

    }

    public getIndexItems(): any[] {
        return this.data.index.item;
    }

    public hasIndexItems(): boolean {
      return this.getIndexItems()?.length > 0;
    }

    public getLicenseeName(): string {
        return this.data.licensee['@attributes'].name;
    }

    public getUid(): string {
      return this.data.licensee['@attributes'].id;
    }

    public getCid(): string {
      return this.data.catalog['@attributes'].id;
    }

    public getCatalogName(): string {
      return this.data.catalog['@attributes'].name;
    }

    public getFirstPageOffset(): number {
      return parseInt(this.pages['@attributes'].offset) || 0;
    }

    public getLargePageSrcAt(idx: number): string {
      const page: any = this.pages.page[idx];

      if (page === null) {
        return '';
      }

      if (this.pages['@attributes'].pathToJpgPlus !== undefined) {
        return FcSettings.pathPrefix
                + this.pages['@attributes'].pathToJpgPlus
                + page['@attributes'].src
                + '.jpg';
      } else {
        return this.getPageSrcAt(idx);
      }
    }

    public getPageSrcAt(idx: number): string {
        // z.B.: https://fc2.giatamedia.com/cat/00010000/00012700/12709/pages/jpg_xl/12709_0038.jpg
        const page: any = this.pages.page[idx];
        return page == null
                ? ''
                : FcSettings.pathPrefix
                + this.pages['@attributes'].pathToJpg
                + page['@attributes'].src
                + '.jpg';
    }

    public getPageCount(): number {
        return this.pages.page.length;
    }

    public getUnscaledPageWidth(): number {
        return this.pages['@attributes'].width;
    }

    public getUnscaledPageHeight(): number {
        return this.pages['@attributes'].height;
    }

}
