<div class="footer-main">
    <div id="openLicensee"
         class="questionmark"
         (click)="dialogsService.openLicensee()" 
         title="{{langService.getText('IDS_SELECT_HELP_LICENCEE')}}">
    </div>
    <div class="button-container"> 
        
        <div class="button pdf-left" [class.disabled]="appService.viewMode==='doublepageBook' && bookService.isFirstPage()" 
             (click)="openPdf(-1)" 
             title="{{langService.getText('IDS_TT_PDFLEFT')}}"></div>
        
        <div class="button print-left" [class.disabled]="appService.viewMode==='doublepageBook' && bookService.isFirstPage()" 
             (click)="print(-1)" 
             title="{{langService.getText('IDS_TT_PRINT_LEFT')}}"></div>
        
        <div class="button first" style="margin-left:30px" [class.disabled]="bookService.isFirstPage()" 
             (click)="bookService.firstPage()" 
             title="{{langService.getText('IDS_TT_FIRSTPAGE')}}"></div>
        
        <div class="button minus10" [class.disabled]="bookService.isFirstPage()"
             (click)="bookService.prevPage10()" 
             title="{{langService.getText('IDS_TT_TENPAGES_BACK')}}"></div>
        
        <div class="button minus1" [class.disabled]="bookService.isFirstPage()" 
             (click)="prevPage()" 
             title="{{langService.getText('IDS_TT_ONEPAGE_BACK')}}"></div>

        <input type="text" class="page-number-display" 
               [value]="getDoublePageNumberDisplayText()" 
               (change)="gotoPageByInput($event.target.value)"
               (keyup.enter)="gotoPageByInput($event.target.value)"
               (keyup)="handleInputKeyUp($event)" 
        />       
        
        <div class="button plus1" [class.disabled]="bookService.isLastPage()" 
             (click)="nextPage()" 
             title="{{langService.getText('IDS_TT_ONEPAGE_FORWARD')}}"></div>
        
        <div class="button plus10" [class.disabled]="bookService.isLastPage()" 
             (click)="bookService.nextPage10()" 
             title="{{langService.getText('IDS_TT_TENPAGES_FORWARD')}}"></div>
        
        <div class="button last" style="margin-right:30px" [class.disabled]="bookService.isLastPage()" 
             (click)="bookService.lastPage()" 
             title="{{langService.getText('IDS_TT_LASTPAGE')}}"></div>
        
        <div *ngIf="appService.viewMode==='doublepageBook'" class="button pdf-right" [class.disabled]="bookService.isLastPage()" 
             (click)="openPdf(0)" 
             title="{{langService.getText('IDS_TT_PDFRIGHT')}}"></div>
        
        <div *ngIf="appService.viewMode==='doublepageBook'" class="button print-right" [class.disabled]="bookService.isLastPage()"
             (click)="print(0)" 
             title="{{langService.getText('IDS_TT_PRINT_RIGHT')}}"></div>
    </div>
    <div class="powered-by">
        <img src="../../../assets/ui/poweredByGiata.svg" />
    </div>
</div>
