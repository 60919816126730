<div class="search-main modal-dialog">
    <div class="header"><div class="header-title">{{langService.getText('IDS_SEARCH_TITLE')}}</div></div>
    <div class="content">
        <div class="input-container">
            <div>{{langService.getText('IDS_SEARCHFOR')}}</div>
            <input #input type="text" class="input" 
              (keyup.enter)="searchFor(input.value)" 
              [value]="searchService.inputValue"
              (change)="searchService.inputValue=input.value" />
            <div class="button" (click)="searchFor(input.value)"></div>
        </div> 

        <div class="subheader" [innerHTML]="subHeaderText"></div>
        <div class="list-container" *ngIf="searchService.searchResult$ | async as searchResult">
            <fc-search-item class="search-item-container" *ngFor="let item of searchResult.items" [itemObject]="item" (click)="gotoPage(item.pageno)"></fc-search-item>  
        </div>
    </div>
    <div class="modal-button-container">
        <div class="modal-button" (click)="dialogsService.closeSearch()">{{langService.getText('IDS_INDEX_CLOSE')}}</div>
    </div>   
</div>
