import { Injectable } from '@angular/core';

@Injectable()
export class DialogsService {
    public static readonly INDEX: number = 1;
    public static readonly SEARCH: number = 4;
    public static readonly LICENSEE: number = 64;
    public static readonly ERROR: number = 256;

    private currentDialogId = 0;

    public closeAllClickOutsideDialogs(): void {
        this.currentDialogId = 0;
    }

    public isDialogOpen(): boolean {
        return this.currentDialogId !== 0;
    }

    // index
    public openIndex() {
        this.currentDialogId = DialogsService.INDEX;
    }

    public closeIndex() {
        this.currentDialogId = 0;
    }

    public isIndexOpen(): boolean {
        return this.currentDialogId === DialogsService.INDEX;
    }

    public isAnotherOpenThanIndex(): boolean {
        return this.currentDialogId !== 0 && this.currentDialogId !== DialogsService.INDEX;
    }


    // search
    public isSearchOpen(): boolean {
        return this.currentDialogId === DialogsService.SEARCH;
    }

    public isAnotherOpenThanSearch(): boolean {
        return this.currentDialogId !== 0 && this.currentDialogId !== DialogsService.SEARCH;
    }


    public openSearch() {
        this.currentDialogId = DialogsService.SEARCH;
    }

    public closeSearch() {
        this.currentDialogId = 0;
    }

    // licensee
    public openLicensee() {
        this.currentDialogId = DialogsService.LICENSEE;
    }

    public closeLicensee() {
        this.currentDialogId = 0;
    }

    public isLicenseeOpen(): boolean {
        return this.currentDialogId === DialogsService.LICENSEE;
    }

    public isAnotherOpenThanLicensee(): boolean {
        return this.currentDialogId !== 0 && this.currentDialogId !== DialogsService.LICENSEE;
    }

    // error
    public openError() {
      this.currentDialogId = DialogsService.ERROR;
    }

    public closeError() {
      this.currentDialogId = 0;
    }

    public isErrorOpen(): boolean {
        return this.currentDialogId === DialogsService.ERROR;
    }
}

