<div class="header-main">
    <div class="side left">
      <div *ngIf="dataService.hasIndexItems()" class="button index"
          [class.disabled]="dialogsService.isAnotherOpenThanIndex()" 
          (click)="dialogsService.openIndex()" 
          title="{{langService.getText('IDS_INDEX')}}">{{langService.getText('IDS_BTN_INDEX')}}</div>
    </div>
    <div class="side right">
      <div class="button search" 
          [class.disabled]="dialogsService.isAnotherOpenThanSearch()" 
          (click)="dialogsService.openSearch()" 
          title="{{langService.getText('IDS_SEARCH')}}">
          <img src="/assets/ui/searchBtnIcn.svg">{{langService.getText('IDS_SEARCH')}}</div>
</div>
