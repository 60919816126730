<div *ngIf="!finishedLoading" class="loading-container">
  <div class="load-failed-message" *ngIf="loadFailedMsg!==''">{{loadFailedMsg}}</div>
  <fc-loading-indicator *ngIf="loadFailedMsg===''"></fc-loading-indicator>
</div>
<div *ngIf="finishedLoading" class="fc main" [ngStyle]="getMainStyle()">
    <fc-header id="header"></fc-header>
    <fc-book-single-page *ngIf="appService.viewMode==='singlepageBook'" [style.height]="metricsService.singleModePageHeight+'px'"></fc-book-single-page>
    <fc-book-double-page *ngIf="appService.viewMode==='doublepageBook'" [style.height]="metricsService.doubleModePageHeight+'px'"></fc-book-double-page>
    <fc-footer class="footer-container"></fc-footer>
    <fc-dialogs class="dialogs-container"></fc-dialogs>
   
</div>
<fc-fullspace-view *ngIf="finishedLoading && appService.fullspaceIsOpen" [@show-hide-fullspace]></fc-fullspace-view>
