<div class="index-main modal-dialog">
    <div class="header"><div class="header-title">{{langService.getText('IDS_TITLE_DESTINATION')}}</div></div>
    <div class="content">
        <div *ngFor="let item of items">
            <fc-index-item [itemObject]="item" (rowClick)="handleItemClick($event)"></fc-index-item>
        </div>
    </div>
    <div class="modal-button-container">
        <div class="modal-button" (click)="dialogsService.closeIndex()">{{langService.getText('IDS_INDEX_CLOSE')}}</div>
    </div>
</div>
