import {AfterViewInit, Component, ElementRef, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BookService, PageChange } from '../data/book.service';
import { DataService } from '../data/data.service';
import { LangService } from '../data/lang.service';
import { MetricsService } from '../metrics.service';
import { showHideFullspace } from './app.animations';
import { AppService } from './app.service';
import { DialogsService } from './dialogs/dialogs.service';

declare var FcSettings;

@Component({
    selector: 'fc-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
    animations: [
      showHideFullspace,
    ]

})
export class AppComponent implements OnInit, AfterViewInit {
    public loadFailedMsg = '';
    public finishedLoading = false;
    private delayedMetricsUpdate: any;

    constructor(
      public appService: AppService,
      public dataService: DataService,
      public langService: LangService,
      public dialogsService: DialogsService,
      public bookService: BookService,
      private elementRef: ElementRef,
      public metricsService: MetricsService,
      private route: ActivatedRoute,
      private router: Router,
    ) {
    }

    private readUrlParams(): void {
      const url: URL = new URL(window.location.href);
      if (url.searchParams) {
        if (url.searchParams.get('fc')) {
          FcSettings.contentPath = 'flipcat/getJS.php?k=' + encodeURIComponent(url.searchParams.get('fc'));
        }
        
        if (url.searchParams.get('lang')) {
          // TODO: CHECKME: needs this to be url-encoded? (did it because it was before)
          FcSettings.lang = encodeURIComponent(url.searchParams.get('lang'));
        }
        
        if (url.searchParams.get('bgcolor')) {
          FcSettings.bgColor = url.searchParams.get('bgcolor');
        }
        
        if (url.searchParams.get('title')) {
          FcSettings.title = url.searchParams.get('title');
        }

        if (url.searchParams.get('page')) {
          FcSettings.page = url.searchParams.get('page');
        }
      }
    }

    public getMainStyle(): any {
        return {
          width: this.metricsService.doubleModeBookWidth + 'px',
        };
    }

    public ngOnInit(): void {
        this.readUrlParams();
        if(FcSettings.contentPath) {
          const url: string = FcSettings.pathPrefix + FcSettings.contentPath;
          this.dataService.loadContentData(url).then(
              (value) => this.loadLangData(),
              (value) => this.handleLoadFailed(value)
          );
        }else{
          this.handleLoadFailed('fc Parameter missing');
        }
    }

    public ngAfterViewInit(): void {
      if (FcSettings.bgColor) {
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor
          = FcSettings.bgColor;
      }

      if(FcSettings.title){
        this.elementRef.nativeElement.ownerDocument.title = FcSettings.title;
      };

    }

    public get loadStatus(): number {
        let status = 0;
        if (this.dataService.loadFinished()) { status++; }
        if (this.langService.loadFinished()) { status++; }

        return status;
    }

    public loadedFinished(): boolean {
        return this.dataService.loadFinished() && this.langService.loadFinished();
    }

    private loadLangData(): void {
        const url: string = FcSettings.pathPrefix
                            + FcSettings.l10nPath
                            + '?lang=' + FcSettings.lang
                            + '&uid=' + this.dataService.getUid()
                            + '&cid=' + this.dataService.getCid();

        this.langService.loadContentData(url).then(
            (value) => this.loadFinished(),
            (value) => this.handleLoadFailed(value)
        );

    }

    public loadFinished(): void {
        console.log('loadFinished');
        this.everythingsReady();
    }

    private handleLoadFailed(value): void {
        console.log('load Failed: ' + value);
        this.loadFailedMsg = 'Load Failed: ' + value;
    }

    private everythingsReady() {
      const startPage = FcSettings.page
        ? parseInt(FcSettings.page) - 1 - this.dataService.getFirstPageOffset() 
        : 0;

      if (startPage === 0) {
          if (this.dataService.hasIndexItems()) {
            this.dialogsService.openIndex();
          }
      }
      this.metricsService.setMainContainer(this.elementRef.nativeElement);
      this.addEventListeners();
      this.updateMode();
      this.bookService.showPage(startPage);
      this.finishedLoading = true;

      this.bookService.pageChange$.subscribe((pageChange: PageChange) => {
        const page = pageChange.pageIndex + this.dataService.getFirstPageOffset() + 1;
        this.router.navigate(['.'], { 
          relativeTo: this.route, 
          queryParams: { page: page},
          queryParamsHandling: 'merge',
        });
      })
    }

    private updateMode(): void {
      const isLandscape = this.elementRef.nativeElement.clientWidth > this.elementRef.nativeElement.clientHeight;
      if (isLandscape) {
        this.appService.viewMode = 'doublepageBook';
      } else {
        this.appService.viewMode = 'singlepageBook';
      }

      this.metricsService.update();
    }

    private addEventListeners() {
      window.addEventListener('resize', (event) => {
        clearTimeout (this.delayedMetricsUpdate);
        this.delayedMetricsUpdate = setTimeout(() => {
          this.updateMode();
        }, 500);
      });

      window.addEventListener('keyup', event => {
        if (!this.dialogsService.isDialogOpen()) {
          switch (event.key) {
            case 'ArrowLeft':
              if (this.appService.viewMode === 'doublepageBook') {
                this.bookService.prevDoublePage();
              } else {
                this.bookService.prevPage();
              }
              break;

            case 'ArrowRight':
              if (this.appService.viewMode === 'doublepageBook') {
                this.bookService.nextDoublePage();
              } else {
                this.bookService.nextPage();
              }
              break;
          }
        }
      });
    }
}
