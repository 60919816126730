import { Injectable, NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { AppComponent } from './components/app.component';
import { BookDoublePageComponent } from './components/book/book-double-page/book-double-page.component';
import { BookFlipService } from './components/book/book-flip.service';
import { PageComponent } from './components/book/page.component';
import { HeaderComponent } from './components/header.component';
import { FooterComponent } from './components/footer.component';
import { IndexComponent } from './components/dialogs/index/index.component';
import { IndexItemComponent } from './components/dialogs/index/indexItem.component';
import { DataService } from './data/data.service';
import { LangService } from './data/lang.service';
import { DialogsService } from './components/dialogs/dialogs.service';
import { DialogsComponent } from './components/dialogs/dialogs.component';
import { SearchComponent } from './components/dialogs/search/search.component';
import { SearchItemComponent } from './components/dialogs/search/searchItem.component';
import { LicenseeComponent } from './components/dialogs/licensee/licensee.component';
import * as $ from 'jquery';
import * as Hammer from 'hammerjs';
import { GlobalErrorService } from './utils/global-error.service';
import { ErrorComponent } from './components/dialogs/error/error.component';
import { FullspaceViewComponent } from './components/fullspace-view/fullspace-view.component';
import { BookService } from './data/book.service';
import { AppService } from './components/app.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BookSinglePageComponent } from './components/book/book-single-page/book-single-page.component';
import { MetricsService } from './metrics.service';
import { SearchService } from './components/dialogs/search/search.service';
import { LoadingIndicatorComponent } from './components/widgets/loading-indicator/loading-indicator.component';
import { RouterModule } from '@angular/router';
@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: Hammer.DIRECTION_HORIZONTAL },
    pan: { direction: Hammer.DIRECTION_ALL },
  } as any;
}

@NgModule({
    imports: [
      BrowserModule,
      HammerModule,
      BrowserAnimationsModule,
      RouterModule.forRoot([
        { path: '', component: AppComponent },
        { path: '**', component: AppComponent },
      ]),
    ],
    declarations: [AppComponent,
                    PageComponent,
                    BookDoublePageComponent,
                    HeaderComponent,
                    DialogsComponent,
                    IndexComponent,
                    IndexItemComponent,
                    FooterComponent,
                    SearchComponent,
                    SearchItemComponent,
                    LicenseeComponent,
                    ErrorComponent,
                    FullspaceViewComponent,
                    BookSinglePageComponent,
                    LoadingIndicatorComponent],
    providers: [
                AppService,
                DataService,
                LangService,
                BookFlipService,
                BookService,
                DialogsService,
                MetricsService,
                SearchService,
                {
                  provide: HAMMER_GESTURE_CONFIG,
                  useClass: MyHammerConfig,
                },
                { provide: ErrorHandler, useClass: GlobalErrorService },
              ],
    bootstrap: [ AppComponent ]
})
export class AppModule {
    constructor() {

    }
}
