import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { DialogsService } from '../dialogs.service';
import { LangService } from '../../../data/lang.service';
import { DataService } from '../../../data/data.service';
import { SearchResult, SearchService } from './search.service';
import { Subscription } from 'rxjs';
import { BookService } from 'src/app/data/book.service';

@Component({
    selector: 'fc-search',
    templateUrl: './search.component.html'
})

export class SearchComponent implements OnInit, OnDestroy {

    @ViewChild('input')
    private inputElement: ElementRef;

    private subscriptions:Subscription[] = [];
    public subHeaderText = '';

    constructor(public dialogsService: DialogsService,
                public langService: LangService,
                public dataService: DataService,
                public searchService: SearchService,
                private bookService: BookService,
                ) {
    }

    public ngOnInit(): void {
      this.subscriptions.push(
        this.searchService.searchResult$.subscribe((searchResult: SearchResult) => {
          switch(searchResult.status){
            
            case 'idle':
              this.subHeaderText = '';
              break;

            case 'ok':
              if (searchResult.items.length === 0) {
                this.subHeaderText = this.langService.getText('IDS_NOSEARCHRESULTS');
              } else {
                  this.subHeaderText = this.langService.getText('IDS_NUMSEARCHRESULTS');
                  this.subHeaderText = this.subHeaderText.replace('{x}', '<b>' + searchResult.query + '</b>');
                  this.subHeaderText = this.subHeaderText.replace('{y}', String(searchResult.count));
              }
              break;

            case 'error':
              this.subHeaderText = this.langService.getText('IDS_NOCONNECTION');
              break;
  
          }
        })
      );

      requestAnimationFrame(() => {       
        this.inputElement.nativeElement?.focus();
        this.inputElement.nativeElement?.select();
      });
    }

    public ngOnDestroy(): void {
      this.subscriptions.forEach(sub => sub.unsubscribe);
      this.subscriptions.length = 0;
    }

    public searchFor(word: string): void {
        if (word.length > 1) {
          this.subHeaderText = '';
          this.searchService.searchFor(word);
        }
    }

    public gotoPage(pageIdx: number): void {
        this.bookService.showPage(pageIdx-1);
        this.dialogsService.closeSearch();
    }    
}
