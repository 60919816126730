import { ErrorHandler, Injectable } from '@angular/core';
import { DialogsService } from '../components/dialogs/dialogs.service';

@Injectable()
export class GlobalErrorService implements ErrorHandler {

  private logger = window.console;
  public lastError: unknown;

  constructor(
    private dialogsService: DialogsService,
  ) { }


  public handleError(err: unknown): void {
    this.logger.log('handleError: ', err);
    this.lastError = err;
    setTimeout(() => this.dialogsService.openError(), 100);
    try {
      // NOTE: otherwise we might end up getting a "converting circular structure to json" error
      if (typeof err === 'object' && err !== null && err.toString) {
        this.logger.error(err.toString());
      } else {
        this.logger.error(err);
      }
    } catch (loggingError) {
      this.logger.error('---LOGGING FAILED---');
      console.error(loggingError);
      console.error(err);
    }
  }
}
