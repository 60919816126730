import { Component } from '@angular/core';
import { DialogsService } from '../dialogs.service';
import { LangService } from '../../../data/lang.service';
import { DataService } from '../../../data/data.service';

@Component({

    selector: 'fc-licensee',
    templateUrl: './licensee.component.html'
})


export class LicenseeComponent {
    constructor(public dialogsService: DialogsService,
                public langService: LangService,
                public dataService: DataService
    ) {
    }

}
