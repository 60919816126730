import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { BookService } from 'src/app/data/book.service';
import { DataService } from 'src/app/data/data.service';
import { AppService } from '../app.service';

@Component({
  selector: 'fc-fullspace-view',
  templateUrl: './fullspace-view.component.html',
  styleUrls: ['./fullspace-view.component.less']
})
export class FullspaceViewComponent implements OnDestroy {

    @ViewChild('scrollContainer')
    private scrollContainer: ElementRef;

    private _isZoomedIn = false;
    public allowTransition = false;
    private initialScrollPos = { x: 0, y: 0 };
    private hideButtonsTimeoutId;
    public showButtons = true;
    private keyupCallback = (event: KeyboardEvent) => this.onKeyUp(event);

    constructor(
      public appService: AppService,
      public bookService: BookService,
      public dataService: DataService,
      private element: ElementRef,
    ) {
      this.startHideButtonsTimeout();
      window.addEventListener('keyup', this.keyupCallback);
     }

    public ngOnDestroy(): void {
      this.clearHideButtonsTimeout();
      window.removeEventListener('keyup', this.keyupCallback);
    }

    public set isZoomedIn(flag: boolean) {
      this._isZoomedIn = flag;
      this.allowTransition = true;
      setTimeout(() => this.allowTransition = false, 400);
    }

    public get isZoomedIn(): boolean {
      return this._isZoomedIn;
    }

    public startHideButtonsTimeout(): void {
      this.clearHideButtonsTimeout();
      this.hideButtonsTimeoutId = setTimeout(() => this.showButtons = false, 3000);
    }

    private clearHideButtonsTimeout(): void {
      clearTimeout(this.hideButtonsTimeoutId);
    }

    private getWidthAndHeight(pw: number, ph: number, availWidth: number, availHeight: number, mode: 'contain' | 'cover') {
      const f = pw / ph;
      const landscape = pw / availWidth < ph / availHeight;
      if ((mode === 'cover' && landscape) || (mode === 'contain' && !landscape)) {
        return {
          width: f * availHeight + 'px',
          height: availHeight + 'px',
        };
      } else {
        return {
          width: availWidth + 'px',
          height: availWidth / f + 'px',
        };
      }
    }

    public getImageContainerStyle(): object {

      if (!this.scrollContainer) {
        return {};
      }

      const pw = this.dataService.getUnscaledPageWidth();
      const ph = this.dataService.getUnscaledPageHeight();

      if (this.isZoomedIn) {
        return this.getWidthAndHeight(pw, ph,
                                      this.scrollContainer.nativeElement.clientWidth,
                                      this.scrollContainer.nativeElement.clientHeight,
                                      'contain');
      } else {
        return this.getWidthAndHeight(pw, ph,
                                      this.scrollContainer.nativeElement.clientWidth,
                                      this.scrollContainer.nativeElement.clientHeight - 88,
                                      'cover');
      }
    }

    public handleImagePanStart(event): void {
      this.initialScrollPos.x = this.scrollContainer.nativeElement.scrollLeft;
      this.initialScrollPos.y = this.scrollContainer.nativeElement.scrollTop;
    }

    public handleImagePan(event): void {
      this.scrollContainer.nativeElement.scrollLeft = this.initialScrollPos.x - event.deltaX;
      this.scrollContainer.nativeElement.scrollTop = this.initialScrollPos.y - event.deltaY;
    }

    public handleMouseMove(): void {
      this.showButtons = true;
      this.startHideButtonsTimeout();
    }

    public handleSwipe(event): void {
      if (event.deltaX < 0) {
        this.bookService.nextPage();
      } else {
        this.bookService.prevPage();
      }
    }

    public close(): void {
      this.appService.closeFullspace();
    }

    public toggleZoom(event): void {
      this.isZoomedIn = !this.isZoomedIn;
    }

    public toggleFullscreen(): void {
      if (document.fullscreenElement) {
        this.closeFullscreen();
      } else {
        this.openFullscreen();
      }
    }

    /*
    Note that we must include prefixes for different browsers, as they don't support the requestFullscreen method yet
    */
    private openFullscreen() {
      const elem = this.element.nativeElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
      }
    }

    private closeFullscreen() {
      document.exitFullscreen();
    }

    private onKeyUp(event: KeyboardEvent): void {
      if (event.key === 'Escape') {
        this.close();
      }
    }
}
