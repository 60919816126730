import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { DialogsService } from '../dialogs.service';
import { LangService } from '../../../data/lang.service';
import { DataService } from '../../../data/data.service';
import { IndexItemComponent } from './indexItem.component';
import { BookService } from 'src/app/data/book.service';

@Component({
    selector: 'fc-index',
    templateUrl: './index.component.html'
})

export class IndexComponent implements OnInit {
  public items: any;

    constructor(
      public dialogsService: DialogsService,
      public langService: LangService,
      public dataService: DataService,
      private bookService: BookService,
      @Inject(ElementRef) private elementRef: ElementRef
    ) {
    }

    public ngOnInit(): void {
        this.items = this.dataService.getIndexItems();
    }

    public handleItemClick(event): void {
        const target: IndexItemComponent = event.target as IndexItemComponent;
        const page: number = parseInt(target.itemObject['@attributes'].page) - this.dataService.getFirstPageOffset();
        this.bookService.showPage(page-1);
        this.dialogsService.closeIndex();
    }

}
