import { Component, OnInit, ElementRef, Inject, Input } from '@angular/core';
import { DialogsService } from './dialogs.service';
import * as $ from 'jquery';

@Component({

    selector: 'fc-dialogs',
    templateUrl: './dialogs.component.html'
})


export class DialogsComponent implements OnInit {

    constructor(
        @Inject (ElementRef) protected elementRef: ElementRef,
        public dialogsService: DialogsService
    ) {
    }

    private pathHasClassName(path: HTMLElement[], className: string): boolean {
      return path.filter(elem => elem.className).some(elem => elem.className.includes(className));
    }

    public ngOnInit(): void {
        window.document.addEventListener('click', (event) => {
            const $header: JQuery = $('#header');
            const $openLicensee: JQuery = $('#openLicensee');

            const target: HTMLElement = event.target as HTMLElement;

            if (this.elementRef.nativeElement.contains(target)
                || $header[0].contains(target)
                || $openLicensee[0].contains(target)
                || this.pathHasClassName(event.composedPath() as HTMLElement[], 'ui-menu-item-wrapper')
                || this.pathHasClassName(event.composedPath() as HTMLElement[], 'ui-datepicker')
              ) {
            } else {
                if (this.dialogsService.isDialogOpen()) {
                    this.dialogsService.closeAllClickOutsideDialogs();
                }

            }
        });
    }

}
