import { Injectable } from '@angular/core';
import { RequestLoader } from './RequestLoader';
declare var FcSettings;

@Injectable()


export class LangService {
    private data: any;
    private loader: RequestLoader;

    constructor() {
        this.loader = new RequestLoader();
    }

    public loadFinished(): boolean {
        return this.loader.status === RequestLoader.STATUS_OK;
    }

    public loadContentData(url: string): Promise <any> {
        return new Promise<any>((resolve, reject) => {
            this.loader.get(url).then(
                (value) => {
                    this.handleDataLoadFinished(value);
                    resolve(true);
                },
                (value) => {
                    this.handleDataLoadedReject(value);
                    reject(false);
                }
            );

        });
    }

    private handleDataLoadFinished(value): void {
        this.data = JSON.parse(value);
    }

    private handleDataLoadedReject(value): void {

    }

    private filterId(id: string): string {
        const a: any[] = this.data.str.filter(
            (value, idx) => {
                return value['@attributes'].id === id;
            }
        );

        if (a && a.length > 0) {
            return a[0]['@attributes'].val;
        }

        return '';
    }

    public getText(id: string): string {
        return this.filterId(id);
    }


}
