import { Component } from '@angular/core';
import { BookFlipService } from '../book-flip.service';
import { LangService } from '../../../data/lang.service';
import { DialogsService } from '../../dialogs/dialogs.service';
import { BookService } from 'src/app/data/book.service';
import { AppService } from '../../app.service';

@Component({
    selector: 'fc-book-double-page',
    templateUrl: './book-double-page.component.html',
    styleUrls: ['./book-double-page.component.less'],
})

export class BookDoublePageComponent {

    constructor(
        public bookFlipService: BookFlipService,
        public bookService: BookService,
        public langService: LangService,
        private dialogsService: DialogsService,
        public appService: AppService,
    ) {
    }

    public handleRightSideGesture() {
      if (!this.dialogsService.isDialogOpen()) {
        this.bookService.nextDoublePage();
      }
    }

    public handleLeftSideGesture() {
      if (!this.dialogsService.isDialogOpen()) {
        this.bookService.prevDoublePage();
      }
    }

    public openFullspaceView(offset: number): void {
      const desiredIndex = this.bookService.doublePageBasedIndex + offset;
      if (desiredIndex >= this.bookService.firstPageIdx && desiredIndex <= this.bookService.lastPageIdx) {
        this.appService.openFullspaceViewAt(desiredIndex);
      }
    }

}
