import { Component, Input, AfterViewInit } from '@angular/core';

@Component({
    selector: 'fc-search-item',
    templateUrl: './searchItem.component.html',
    styleUrls: ['./searchItem.component.less'],
})

export class SearchItemComponent implements AfterViewInit {
    @Input() public itemObject: any;

    constructor() {
    }

    public ngAfterViewInit(): void {
    }
}
